<template>
  <div class="firmbg">
    <!-- 登录 -->
    <div class="logintops" v-if="loginindex">
      <div style="position: fixed;
    top: 25px;
    font-size: 24px;
    font-weight: 700;
    color: #fff;">企业登录</div>
      <div>
        <input type="text" v-model="loginfrom.uname" placeholder="输入手机号" />
        <input
          type="password"
          v-model="loginfrom.upwd"
          placeholder="输入密码"
        />
      </div>
      <div>
        <van-button class="loginbutton" round type="info" @click="login()"
          >登 录</van-button  
        >
        <van-button class="loginbutton" round style="margin-top:15px"  @click="quit()"
          >返 回 首 页</van-button  
        >
      </div>
      <div style="margin: 15px; color: #1989fa" @click="enroll()">注册</div>
    </div>
    <!-- 注册 二 -->
    <div class="logintops" style="padding-bottom: 15px;" v-if="enrollindex">
      <div style="position: fixed;
    top: 25px;
    font-size: 24px;
    font-weight: 700;
    color: #fff;">企业注册</div>
      <div class="enrollfromstyle">
        <input
          type="text"
          v-model="enrollfrom.LicCode"
          placeholder="营业执照编码"
        />
        <input type="text" v-model="enrollfrom.Admin" placeholder="输入管理员" />
        <input
          type="text"
          v-model="enrollfrom.Mobile"
          placeholder="输入管理员手机号"
        />
        <button v-if="show" @click="ValidCodeRandom()" class="shortmsgons">
          获取短信验证码
        </button>
        <button v-if="!show" class="shortmsgons">
          {{ count }} 秒后重新获取
        </button>
        <input
          type="text"
          v-model="enrollfrom.ShortCode"
          placeholder="输入短信验证码"
        />
        
        <input
          type="password"
          v-model="enrollfrom.LoginPsw"
          placeholder="输入密码"
        />
        <input
          type="password"
          v-model="enrollfrom.upwd"
          placeholder="再次输入密码"
        />
        <input type="text" v-model="enrollfrom.Name" placeholder="输入企业名称" />
        <input
          type="text"
          @click="sexshow = true" 
          v-model="enrollfrom.IsWithInName"
          placeholder="是否辖区内企业"
          readonly="readonly"
        />
        <van-popup v-model="sexshow" position="bottom" get-container="body">
          <van-picker
            show-toolbar
            :columns="sexlist"
            value-key="Name"
            @cancel="sexshow = false"
            @confirm="onConfirm"
          >
          </van-picker>
        </van-popup>
      </div>
      <div>
        <van-button
          class="loginbutton"
          round
          type="info"
          @click="RegisterOverfrom()"
          >提 交</van-button
        >
      </div>
      <div style="margin:15px; color:#1989fa" @click="loginindexshow">返回登录页面</div>
    </div>
  </div>
</template>
<script>
import {
  RE_Register,
  CheckStaff,
} from "@/api/REplat";
import {SendValidedMsg } from "@/api/ResumeJS"
import { getaccToken, setaccToken } from "@/utils/auth";
import config from "@/config";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data() {
    return {
      loginfrom: {
        //登录提交数据
        uname: "",
        upwd: "",
      },
      enrollfrom: {
        //注册
        IsWithIn:"",   //是否辖区内企业、
        LicCode:"",   //统一社会信用代码、
        Name:"",   //企业名称，、
        Admin:"",   //管理员、
        Mobile:"",   //管理员手机号、
        ShortCode:"",   //短信验证码、
        LoginPsw:"",   //登录密码
      },
      enrollOnefrom: {
        //注册第一步
        ImgVerifier: "", //图片验证者，时间戳、
        ValidCode: "", //图片验证码、
        Mobile: "", //手机号、
        ShortMsg: "", //短信验证码;
      },
      yearofbirth: "", //时间
      loginindex: true, //登录页面
      enrollindex: false, //注册第二步
      enrollone: false, //注册第一步
      dateshow: false, //出生年月
      sexshow: false, //性别
      workingYearshow:false, //工作时间
      minDate: new Date(1970, 0), //
      maxDate: new Date(),
      sexlist: [
        { id: "1", Name: "是" },
        { id: "2", Name: "否" },
      ],
      show: true,
      count: "",
      timer: null,
    };
  },
  created() {
    var token = getaccToken();
    if(token != undefined){
      this.$router.push("/list/company");
    }
  },
  methods: {
    login() {
      //登录
      if (this.loginfrom.uname == "") {
        Toast("用户名不能为空");
        return false;
      }
      if (this.loginfrom.upwd == "") {
        Toast("密码不能为空");
        return false;
      }
      CheckStaff(this.loginfrom).then((res) => {
        if (res.data.code == 0) {
          Toast.success("登录成功");
          console.log(res.data.data.token);
          setaccToken(res.data.data.token);
          this.$router.push({name:'company'});
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 注册按钮
    enroll() {
      this.loginindex = false;
      this.enrollindex = true;
    },
    loginindexshow() {
      this.loginindex = true;
      this.enrollindex = false;
    },
    // 注册 第二步
    RegisterOverfrom() {
      if (this.enrollfrom.LicCode == "") {
        Toast.fail("请输入企业编码!");
        return false;
      }
      if (this.enrollfrom.LoginPsw == "") {
        Toast.fail("请输入登录密码!");
        return false;
      }
      if (this.enrollfrom.LoginPsw !== this.enrollfrom.upwd) {
        Toast.fail("两次密码不同!");
        return false;
      }
      if (this.enrollfrom.Name == "") {
        Toast.fail("请输入企业名称!");
        return false;
      }
      if (this.enrollfrom.Admin == "") {
        Toast.fail("请输入管理员!");
        return false;
      }
      if (this.enrollfrom.Mobile == "") {
        Toast.fail("请输入管理员手机号!");
        return false;
      }
      if (this.enrollfrom.ShortCode == "") {
        Toast.fail("请输入短信验证码!");
        return false;
      }

      RE_Register(this.enrollfrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("注册成功!");
          this.loginindexshow();
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 获取短信验证码
    ValidCodeRandom() {
      if (this.enrollfrom.Mobile == "") {
        Toast.fail("请输入手机号!");
        return false;
      }
      var MobileNum = this.enrollfrom.Mobile;
      SendValidedMsg({ MobileNum: MobileNum }).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("验证码已发送!");
          this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    quit(){
      this.$router.push("/list");
    },
    // 性别
    onConfirm(value, index) {
      console.log(value);
      this.sexshow = false;
      this.enrollfrom.IsWithIn = value.id;
      this.enrollfrom.IsWithInName = value.Name;
    },
    // 出生年月
    ondateConfirm(val){ 
      this.enrollfrom.yearofbirth = this.dateformat(val);
      this.dateshow = false
    },
    // 工作时间
    workingYeardate(val){ 
      this.enrollfrom.workingYear = this.dateformat(val);
      this.workingYearshow = false
    },
    // 日期格式化
    dateformat(val){
      let year = val.getFullYear()
      let month = val.getMonth() + 1
      let day = val.getDate()
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      return `${year}-${month}-${day}`
    }
  },
};
</script>
<style>
.logintops {
  margin: 30px;
  position: relative;
  bottom: -250px;
}
/* .logintops div:nth-child(1){
    font-size: 24px;
    font-weight: 700;
} */
.logintops div:nth-child(2) {
  margin-top: 35px;
}
.logintops div:nth-child(2) input {
  border-radius: 50px;
  width: 88%;
  height: 50px;
  background: #e6e6e6;
  margin: 15px 0px;
  padding-left: 35px;
  color: #000;
  border: none;
  box-shadow: 1px 1px 1px #e6e6e6;
}
.logintops div:nth-child(3) {
  margin-top: 35px;
}
.logintops div:nth-child(3) .loginbutton {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  height: 50px;
}
.logintops .enrollfromstyle input {
  height: 30px !important ;
  margin: 7px 0px !important;
}
.shortmsgons {
  border: none;
  position: absolute;
  right: 0px;
  width: 130px;
  top: 191px;
  border-left: 1px solid;
  height: 33px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 14px;
}
.imgcode {
  position: absolute;
  height: 32px;
  top: 196px;
  right: 32px;
  width: 90px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.firmbg {
  background: url('../../assets/firmlogin.png');
}
</style>